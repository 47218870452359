import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import classNames from 'classnames'

import Button from '../../../../../components/atoms/button'
import Input from '../../../../../components/atoms/input'
import Checkbox from '../../../../../components/atoms/checkbox'
import PhoneNumberInput from '../../../../../components/atoms/input-phone-number'

import { getCountryOptionsData } from '../../../../../utils'

import SelectComponent, {
  SelectOptions,
} from '../../../../../components/atoms/select'
import RequiredField from '../../../../../components/atoms/required-form-field'

import InputGroup from '../../../../../components/molecules/input-group'
import SelectCompaniesHouse from '../../../../../components/molecules/select-companies-house'

import { transition } from '../../../../../styles'

type Address = {
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  region?: string
  locality?: string
  country?: string
}

type Member = {
  firstName: string
  lastName: string
  phoneNumber: string
}

type Company = {
  name?: string
  number?: string
  type?: string
  address?: Address
  enableBillingAddress?: boolean
  billingAddress?: Address
}

export type FormValues = {
  houseAPI?: SelectOptions
  company?: Company
  member?: Member
}

type CreateCompanyAndMemberFormProps = {
  onSubmit: (formData: FormValues) => void
  defaultValues: FormValues
}

const CreateCompanyAndMemberForm: React.FC<CreateCompanyAndMemberFormProps> = ({
  onSubmit,
  defaultValues,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.onboarding',
  })

  const countryOptions = useMemo(() => getCountryOptionsData(), [])

  const mappedCountryOptions = useMemo(() => {
    return (
      countryOptions.map((country) => ({
        value: country.alpha3,
        label: country.country,
        key: country.alpha3,
      })) || []
    )
  }, [countryOptions])

  const {
    handleSubmit,
    formState: { errors: formErrors, isSubmitting },
    control,
    setValue,
    watch,
  } = useForm<FormValues>({
    defaultValues,
  })

  const handleSubmitForm = (formData: FormValues) => {
    const {
      company: {
        name,
        number,
        type,
        address,
        enableBillingAddress,
        billingAddress,
      },
      member,
    } = formData as any

    if (address && !address.region) {
      address.region = null
    }

    if (address && !address.addressLine2) {
      address.addressLine2 = null
    }

    const payload = {
      company: {
        name,
        number: number || null,
        type: type || null,
        address,
        billingAddress,
      },
      member,
    }

    if (enableBillingAddress) {
      payload.company.billingAddress = billingAddress
    } else {
      payload.company.billingAddress = address
    }

    onSubmit(payload)
  }

  useEffect(() => {
    if (defaultValues) {
      /**
       * @TODO after select async component is fixed and tested this should be chaned
       */
      let country: SelectOptions | undefined | any

      if (defaultValues.company?.address?.country) {
        const filteredCoutries = mappedCountryOptions.filter(
          (item: any) => item.value === defaultValues.company?.address?.country,
        )
        if (filteredCoutries.length > 0) {
          country = filteredCoutries[0]
        }
      }

      setValue('company', defaultValues.company)
      setValue('company.address.country', country)
      setValue('member', defaultValues.member)
    }

    if (watch('houseAPI.value')) {
      const { name, companyNumber, companyType, completeAddress } =
        JSON.parse(watch('houseAPI.value')) || {}

      /**
       * @TODO after select async component is fixed and tested this should be chaned
       */
      let country: SelectOptions | undefined | any

      if (completeAddress?.country) {
        const filteredCoutries = mappedCountryOptions.filter(
          (item) => item.label === completeAddress.country,
        )
        if (filteredCoutries.length > 0) {
          country = filteredCoutries[0]
        }
      }

      setValue('company', {
        name,
        type: companyType,
        number: companyNumber,
        address: {
          addressLine1: completeAddress?.address_line_1 || undefined,
          addressLine2: completeAddress?.address_line_2 || undefined,
          postalCode: completeAddress?.postal_code || undefined,
          region: completeAddress?.region || undefined,
          locality: completeAddress?.locality || undefined,
          country,
        },
      })
      setValue('company.address.country', country)
    }
  }, [defaultValues, watch('houseAPI')])

  return (
    <div className="flex flex-col items-center">
      <form
        className={classNames(
          transition,
          'fade-in',
          'flex flex-col gap-y-3 w-full max-w-[500px]',
        )}
        onSubmit={handleSubmit(handleSubmitForm)}
      >
        <section className="flex flex-col text-ppa/title gap-y-3 mx-6">
          <div className="flex flex-col items-start justify-start mb-5">
            <h2 className="text-2xl font-medium mb-3">
              {t('setup.addCompany.title')}
            </h2>
            <span className="text-base font-light">
              {t('setup.addCompany.info')}
            </span>
          </div>

          <Controller
            name="houseAPI"
            control={control}
            render={({ field: { ref: _, onChange, ...props } }) => (
              <InputGroup
                label={t('setup.company.title')}
                infoLabel={t('setup.company.infoTitle')}
                error={formErrors.company?.message}
              >
                <SelectCompaniesHouse
                  {...props}
                  onChange={onChange}
                  placeholder={t('setup.company.placeholder')}
                />
              </InputGroup>
            )}
          />
          <div className="w-full border" />
          <Controller
            name="company.name"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('setup.formFields.name.required'),
              },
            }}
            render={({ field: props }) => (
              <InputGroup
                label={t('setup.formFields.name.title')}
                error={formErrors.company?.name?.message as any}
                required
              >
                <Input
                  {...props}
                  error={formErrors.company?.name?.message as any}
                  placeholder={t('setup.formFields.name.placeholder')}
                  attribute="organization"
                />
              </InputGroup>
            )}
          />
          <div className="flex flex-col gap-3 md:flex-row">
            <Controller
              name="company.number"
              control={control}
              render={({ field: props }) => (
                <InputGroup
                  label={t('setup.formFields.number.title')}
                  error={formErrors.company?.number?.message}
                >
                  <Input
                    {...props}
                    placeholder={t('setup.formFields.number.placeholder')}
                    error={formErrors.company?.number?.message}
                  />
                </InputGroup>
              )}
            />
            <Controller
              name="company.type"
              control={control}
              render={({ field: props }) => (
                <InputGroup label={t('setup.formFields.type.title')}>
                  <Input
                    {...props}
                    placeholder={t('setup.formFields.type.placeholder')}
                  />
                </InputGroup>
              )}
            />
          </div>
          <InputGroup label={t('setup.formFields.address.title')}>
            <div
              className={classNames(
                'flex flex-col',
                'pt-5 mt-3 gap-y-3',
                'border-t border-ppa/grayBorder',
                'sm:border-t-0 sm:border-l sm:pt-0 sm:pl-5',
              )}
            >
              <Controller
                name="company.address.addressLine1"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('setup.formFields.addressLine1.required'),
                  },
                }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('setup.formFields.addressLine1.title')}
                    error={
                      formErrors.company?.address?.addressLine1?.message as any
                    }
                    required
                  >
                    <Input
                      {...props}
                      placeholder={t(
                        'setup.formFields.addressLine1.placeholder',
                      )}
                      error={formErrors.company?.address?.addressLine1?.message}
                      attribute="address-line1"
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="company.address.addressLine2"
                control={control}
                render={({ field: props }) => (
                  <InputGroup label={t('setup.formFields.addressLine2.title')}>
                    <Input
                      {...props}
                      placeholder={t(
                        'setup.formFields.addressLine2.placeholder',
                      )}
                      attribute="address-line2"
                    />
                  </InputGroup>
                )}
              />
              <div className="flex flex-col gap-3 md:flex-row">
                <Controller
                  name="company.address.postalCode"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('setup.formFields.postalCode.required'),
                    },
                  }}
                  render={({ field: props }) => (
                    <InputGroup
                      label={t('setup.formFields.postalCode.title')}
                      error={
                        formErrors.company?.address?.postalCode?.message as any
                      }
                      required
                    >
                      <Input
                        {...props}
                        placeholder={t(
                          'setup.formFields.postalCode.placeholder',
                        )}
                        error={formErrors.company?.address?.postalCode?.message}
                        attribute="postal-code"
                      />
                    </InputGroup>
                  )}
                />
                <Controller
                  name="company.address.locality"
                  control={control}
                  render={({ field: props }) => (
                    <InputGroup
                      label={t('setup.formFields.locality.title')}
                      error={
                        formErrors.company?.address?.locality?.message as any
                      }
                      required
                    >
                      <Input
                        {...props}
                        placeholder={t('setup.formFields.locality.placeholder')}
                        error={formErrors.company?.address?.locality?.message}
                      />
                    </InputGroup>
                  )}
                />
              </div>
              <div className="flex flex-col gap-3 md:flex-row">
                <Controller
                  name="company.address.region"
                  control={control}
                  render={({ field: props }) => (
                    <InputGroup
                      label={t('setup.formFields.region.title')}
                      error={
                        formErrors.company?.address?.region?.message as any
                      }
                    >
                      <Input
                        {...props}
                        placeholder={t('setup.formFields.region.placeholder')}
                        error={formErrors.company?.address?.region?.message}
                        attribute="address-level1"
                      />
                    </InputGroup>
                  )}
                />
                <Controller
                  name="company.address.country"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('setup.formFields.country.required'),
                    },
                  }}
                  render={({ field: { ref: _, ...props } }) => (
                    <InputGroup
                      label={t('setup.formFields.country.title')}
                      error={
                        formErrors?.company?.address?.country?.message as any
                      }
                      required
                    >
                      <SelectComponent
                        {...props}
                        type="single"
                        options={mappedCountryOptions}
                        placeholder={t('setup.formFields.country.placeholder')}
                        error={formErrors?.company?.address?.country?.message}
                        testId="select-country"
                      />
                    </InputGroup>
                  )}
                />
              </div>
            </div>
          </InputGroup>

          <div className="flex flex-col">
            <Controller
              name="company.enableBillingAddress"
              control={control}
              render={({ field: props }) => (
                <div className="flex items-center justify-start">
                  <Checkbox
                    label={t('setup.enableBillingAddress.title')}
                    {...props}
                  />
                </div>
              )}
            />
            {watch('company.enableBillingAddress') && (
              <div
                className={classNames(
                  'flex flex-col',
                  'pt-5 mt-3 gap-y-3',
                  'border-t border-ppa/grayBorder',
                  'sm:border-t-0 sm:border-l sm:pt-0 sm:pl-5',
                )}
              >
                <Controller
                  name="company.billingAddress.addressLine1"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('setup.formFields.addressLine1.required'),
                    },
                  }}
                  render={({ field: props }) => (
                    <InputGroup
                      label={t('setup.formFields.addressLine1.title')}
                      error={
                        formErrors.company?.billingAddress?.addressLine1
                          ?.message as any
                      }
                      required
                    >
                      <Input
                        {...props}
                        placeholder={t(
                          'setup.formFields.addressLine1.placeholder',
                        )}
                        error={
                          formErrors.company?.billingAddress?.addressLine1
                            ?.message
                        }
                        attribute="address-line1"
                      />
                    </InputGroup>
                  )}
                />
                <Controller
                  name="company.billingAddress.addressLine2"
                  control={control}
                  render={({ field: props }) => (
                    <InputGroup
                      label={t('setup.formFields.addressLine2.title')}
                    >
                      <Input
                        {...props}
                        placeholder={t(
                          'setup.formFields.addressLine2.placeholder',
                        )}
                        error={
                          formErrors.company?.billingAddress?.addressLine2
                            ?.message
                        }
                        attribute="address-line2"
                      />
                    </InputGroup>
                  )}
                />
                <div className="flex flex-col gap-3 md:flex-row">
                  <Controller
                    name="company.billingAddress.postalCode"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('setup.formFields.postalCode.required'),
                      },
                    }}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('setup.formFields.postalCode.title')}
                        error={
                          formErrors.company?.billingAddress?.postalCode
                            ?.message as any
                        }
                        required
                      >
                        <Input
                          {...props}
                          placeholder={t(
                            'setup.formFields.postalCode.placeholder',
                          )}
                          error={
                            formErrors.company?.billingAddress?.postalCode
                              ?.message
                          }
                          attribute="postal-code"
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name="company.billingAddress.locality"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('setup.formFields.locality.required'),
                      },
                    }}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('setup.formFields.locality.title')}
                        error={
                          formErrors.company?.billingAddress?.locality
                            ?.message as any
                        }
                        required
                      >
                        <Input
                          {...props}
                          placeholder={t(
                            'setup.formFields.locality.placeholder',
                          )}
                          error={
                            formErrors.company?.billingAddress?.locality
                              ?.message
                          }
                        />
                      </InputGroup>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-3 md:flex-row">
                  <Controller
                    name="company.billingAddress.region"
                    control={control}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('setup.formFields.region.title')}
                        error={
                          formErrors.company?.billingAddress?.region
                            ?.message as any
                        }
                      >
                        <Input
                          {...props}
                          placeholder={t('setup.formFields.region.placeholder')}
                          error={
                            formErrors.company?.billingAddress?.region?.message
                          }
                          attribute="address-level1"
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name="company.billingAddress.country"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('setup.formFields.country.required'),
                      },
                    }}
                    render={({ field: { ref: _, ...props } }) => (
                      <InputGroup
                        label={t('setup.formFields.country.title')}
                        error={
                          formErrors?.company?.address?.country?.message as any
                        }
                        required
                      >
                        <SelectComponent
                          {...props}
                          type="single"
                          options={mappedCountryOptions}
                          placeholder={t(
                            'setup.formFields.country.placeholder',
                          )}
                          error={formErrors?.company?.address?.country?.message}
                          testId="select-country-2"
                        />
                      </InputGroup>
                    )}
                  />
                </div>
              </div>
            )}
            <div className="mt-5 flex flex-col gap-3">
              <Controller
                name="member.firstName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('setup.formFields.firstName.required'),
                  },
                }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('setup.formFields.firstName.title')}
                    error={formErrors.member?.firstName?.message as any}
                    required
                  >
                    <Input
                      {...props}
                      placeholder={t('setup.formFields.firstName.placeholder')}
                      error={formErrors.member?.firstName?.message}
                      attribute="given-name"
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="member.lastName"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('setup.formFields.lastName.required'),
                  },
                }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('setup.formFields.lastName.title')}
                    error={formErrors.member?.lastName?.message as any}
                    required
                  >
                    <Input
                      {...props}
                      placeholder={t('setup.formFields.lastName.placeholder')}
                      error={formErrors.member?.lastName?.message}
                      attribute="family-name"
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="member.phoneNumber"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('setup.formFields.contactNumber.required'),
                  },
                }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('setup.formFields.contactNumber.title')}
                    infoLabel={t('setup.formFields.contactNumber.infoLabel')}
                    error={formErrors?.member?.phoneNumber?.message as any}
                    required
                  >
                    <PhoneNumberInput
                      {...props}
                      placeholder={t(
                        'setup.formFields.contactNumber.placeholder',
                      )}
                      error={formErrors?.member?.phoneNumber?.message}
                    />
                  </InputGroup>
                )}
              />
            </div>
          </div>
          <RequiredField label="required fields" />
          <div className="flex items-center justify-end w-full max-w-[150px] ml-auto">
            <Button
              type="submit"
              variant="primary"
              fullWidth
              loading={isSubmitting}
              disabled={isSubmitting}
            >
              {t('setup.next')}
            </Button>
          </div>
        </section>
      </form>
    </div>
  )
}

export default CreateCompanyAndMemberForm
